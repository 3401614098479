<template>
  <div>
    <v-dialog max-width="600" v-model="dialogs.update">
      <v-card class="mx-auto">
        <v-card-title>
          Update Activity
          {{ latestParcel && latestParcel.reference ? `for ${latestParcel.reference}` : '' }}
          <v-spacer> </v-spacer>
          <v-btn icon @click="dialogs.update = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container>
          <ActivityUpdateForm
            :activity-id="activityId"
            @success="onUpdate"
            @cancel="dialogs.update = false"
          />
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog max-width="600" v-model="dialogs.preview">
      <v-card class="mx-auto">
        <v-card-title>
          Land Activity
          <v-spacer> </v-spacer>
          <v-btn icon @click="dialogs.preview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-container>
          <ActivityUpdateForm
            readonly
            :activity-id="activityId"
            @success="onUpdate()"
            @cancel="dialogs.update = false"
          />
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.delete" max-width="400">
      <v-card class="mx-auto">
        <v-card-title></v-card-title>
        <v-card-text>
          <v-alert type="error" text v-if="errors.delete">
            {{ errors.delete }}
          </v-alert>
          <div>Are you sure? Do you want to delete this activity?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="deleteActivity()">Yes</v-btn
          ><v-btn color="secondary" text @click="dialogs.delete = false">No</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <base-data-table
      :loading="loading"
      :headers="headers"
      :items="data"
      class="elevation-1"
      dense
      :sort-desc="true"
      sort-by="date"
    >
      <template v-slot:[`header.issueComment`]="{}">
        <span> Issues/<i>Comments</i> </span>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <span
          :class="{
            'success--text': item.type === 'monitoring' && !item.issues
          }"
        >
          {{ item.type | type }}
        </span>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <span>
          {{ item.date | fmtDate }}
        </span>
      </template>

      <template v-slot:[`item.issueComment`]="{ item }">
        <div>
          <span v-if="schedule(item.date).status"> SCHEDULED </span>
          <span v-else-if="!hasIssue(item) && !item.comments" class="success--text">none</span>
          <TextTruncate
            :value="item.issueDescription"
            v-if="item.issueDescription && hasIssue(item)"
          />
          <i>
            <TextTruncate :value="item.comments" />
          </i>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip
          bottom
          :color="item.issuesResolved ? 'success' : 'warning'"
          v-if="item.type === 'issues' || item.issues"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.issuesResolved" color="success" v-on="on">
              mdi-alert-circle-check-outline
            </v-icon>
            <v-icon v-else color="warning" v-on="on"> mdi-alert-circle-outline </v-icon>
          </template>
          <span v-if="item.issuesResolved"> Issues resolved </span>
          <span v-else>Issues</span>
        </v-tooltip>
        <v-chip small v-if="item.trail" color="info">Trail</v-chip>
        <v-icon @click="showPreview(item)" color="primary"> mdi-text-box-search </v-icon>
        <UpdateDelete
          @update="showUpdateDialog(item.id)"
          @delete="showDeleteDialog(item.id)"
          :permission-delete="permissionDelete(item)"
        />
      </template>
    </base-data-table>
  </div>
</template>

<script>
import Land from '../../services/land';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { fullName } from '../../utils';
const land = new Land();
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    UpdateDelete: () => import('@/components/Feature/UpdateDelete.vue'),
    ActivityUpdateForm: () => import('./ActivityUpdateForm.vue'),
    TextTruncate: () => import('../../components/TextTruncate.vue')
  },
  computed: {
    ...mapGetters(['latestParcel'])
  },
  filters: {
    type(value) {
      const res = land.ACTIVITY_TYPES.filter(type => type.value === value);
      if (res.length) {
        return res[0].text;
      } else {
        return value;
      }
    },
    fmtDate(ts) {
      return moment.unix(ts).format('YYYY-MM-DD');
    }
  },
  data() {
    return {
      unsubscribe: null,
      data: [],
      loading: true,
      dialogs: {
        update: false,
        delete: false,
        preview: false
      },
      preview: null,
      errors: {
        delete: ''
      },
      activityId: '',
      headers: [
        {
          text: 'Date',
          align: 'center',
          sortable: true,
          value: 'date',
          class: 'primary white--text',
          width: 110
        },
        {
          text: 'By',
          align: 'center',
          sortable: false,
          value: 'name',
          class: 'primary white--text'
        },
        {
          text: 'Type',
          align: 'center',
          sortable: false,
          value: 'type',
          class: 'primary white--text'
        },
        {
          text: 'Issues/Comments',
          align: 'left',
          sortable: false,
          value: 'issueComment',
          class: 'primary white--text'
        },
        {
          text: 'Actions',
          align: 'right',
          sortable: false,
          value: 'action',
          class: 'primary white--text',
          width: 150
        }
      ]
    };
  },
  created() {
    this.getData();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    schedule(date) {
      const res = {
        status: false,
        message: '',
        days: 0
      };
      try {
        const dateParsed = moment.unix(date);
        if (dateParsed) {
          const start = moment();
          const end = moment(dateParsed);
          const diff = end.startOf('day').diff(start.startOf('day'), 'days');
          res.days = diff;
          if (diff > 0) {
            res.message = `${diff} day${diff > 1 ? 's' : ''}`;
            res.status = true;
          }
        }
      } catch {
        return res;
      }
      return res;
    },
    hasIssue(activity) {
      return activity.type === 'issues' || activity.issues;
    },
    createdByUser(users) {
      const items = [];
      if (typeof users === 'string') {
        items.push(users);
      } else if (Array.isArray(users)) {
        items.push(...users);
      }
      const user = fullName(this.$store.state.user.profile);
      return items.some(i => {
        return String(i).toLowerCase().trim() === user.toLowerCase().trim();
      });
    },
    permissionDelete(item) {
      return item.createdBy === this.$store.state?.user?.uid || this.createdByUser(item.name);
    },
    showPreview({ id }) {
      this.activityId = id;
      this.dialogs.preview = true;
    },
    onUpdate(action) {
      this.dialogs.update = false;
      this.getData(true);
      this.$emit('success', action);
    },
    refresh() {
      this.getData();
    },
    showUpdateDialog(id) {
      this.dialogs.update = true;
      this.activityId = id;
    },
    showDeleteDialog(id) {
      this.dialogs.delete = true;
      this.activityId = id;
    },
    deleteActivity() {
      this.errors.delete = '';
      this.dialogs.delete = false;
      land
        .deleteActivity(this.activityId)
        .then(() => {
          this.getData();
          const action = {
            feature: 'land',
            action: 'delete'
          };
          this.$emit('success', action);
        })
        .catch(error => {
          this.errors.delete = error;
        });
    },
    getData(emit) {
      land
        .listActivity(this.id, null, data => {
          data = data.map(i => {
            return {
              ...i,
              date: moment(i.date).unix()
            };
          });
          this.data = data;
          if (emit) {
            this.$emit('update');
          }
        })
        .then(unsubscribe => {
          this.unsubscribe = unsubscribe;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
</style>
